import React from 'react'
import infoImg from '../Assets/info-icon.svg'

function InfoText() {
  return (
    <div className='flex-center'>
        <div className='img img-15 mg__right-10'>
            <img src={infoImg} alt='Info' />
        </div>
        <span className='info-text'>Edição limitada</span>
    </div>
  )
}

export default InfoText
