import React from 'react'
import logo from '../Assets/logo-criszuca-white.webp'
import '../Styles/FooterContact.css'

function FooterContact() {
  return (
    <footer id='footer-contact' className='flex-center flex-column flex__gap-50 padding-50'>
        <hr id='line-footer'/>
        <p><span id='text-orange'>Mantenha-se atualizada</span> com as últimas novidades <br/> do universo de <span id='text-red'>"Tudo tem um porquê".</span></p>
        <div>
            <label id='email-label'>Digite seu e-mail</label>
            <div>
                <input/>
                <button id='send-contact-btn'>Enviar</button>
            </div>
        </div>
        <div className='img img-150'>
            <img src={logo} alt='logo Cris Zuca' />
        </div>
        <p>Copyright © Star Agency - Todos os direitos reservados.</p>
    </footer>
  )
}

export default FooterContact
