import React from 'react'
import '../Styles/YouFind.css'
import ButtonDefault from '../Components/ButtonDefault'
import Cards from '../Components/Cards'
import love from '../Assets/love.svg'
import person from '../Assets/person.svg'
import chat from '../Assets/chat.svg'
import time from '../Assets/time.svg'
import favorite from '../Assets/favorite.svg'
import book from '../Assets/book.svg'

function YouFind() {
  return (
    <section id='background-youfind' className='padding-100 flex-center flex-column'>
        <div className='gpc-b_sobreposicao'></div>
        <div className='z-index-top flex-center flex-column flex__gap-50'>
            <div className='flex-center flex-column'>
                <h2 id='subtitle-youfind'>PARA VOCÊ?</h2>
                <h1 id='title-youfind'>VOCÊ ENCONTRARÁ:</h1>
            </div>
            <div id='container-cards' className="flex-center flex__gap-50 flex__wrap">
                <Cards 
                img={love}
                title="Romances Envolventes:"
                description="Histórias que tocam o coração e inspiram. Cada livro é uma jornada emocional que vai te fazer rir, chorar e refletir sobre a vida e o amor."
                />
                <Cards 
                img={person}
                title="Personagens Inesquecíveis:"
                description="Conheça protagonistas que vão marcar a sua vida. Personagens complexos, reais e cativantes que você vai sentir como se conhecesse pessoalmente."
                />
                <Cards 
                img={chat}
                title="Narrativas Emocionantes:"
                description="Tramas que prendem a atenção do início ao fim. Enredos bem construídos que vão te manter vidrado(a) até a última página, com reviravoltas surpreendentes."
                />
                <Cards 
                img={time}
                title="Edições Exclusivas:"
                description="Livros com capas e conteúdos especiais para os fãs. Edições limitadas com ilustrações, prefácios da autora e notas exclusivas que não estão disponíveis em outras versões."
                />
                <Cards 
                img={favorite}
                title="Acesso Direto às Novidades:"
                description="Link para se inscrever e receber as últimas notícias e lançamentos da autora. Seja o primeiro a saber sobre novos livros, eventos e promoções exclusivas."
                />
                <Cards 
                img={book}
                title="Grupo Exclusivo de Leitores:"
                description="Materiais adicionais e conteúdos exclusivos para os primeiros compradores. Inclui capítulos extras, entrevistas com a autora e acesso antecipado a novos lançamentos."
                />
            </div>
            <ButtonDefault text="Quero ler agora"/>
        </div>

    </section>
  )
}

export default YouFind
