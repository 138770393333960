import React from 'react'
import ButtonDefault from '../Components/ButtonDefault'
import cris from '../Assets/criszucass.webp'
import '../Styles/MeetCris.css'

function MeetCris() {
  return (
    <section id='meetcris' className='padding-100 flex-center flex__gap-100'>
        <div id='content-meetcris' className='flex-center flex-column flex__gap-50'>
            <h2>Conheça a <span>Cris Zuca</span></h2>
            <p>Crislaine Zuca, conhecida carinhosamente como Cris Zuca, é uma mulher que equilibra os papéis de mãe dedicada, advogada competente, palestrante inspiradora e escritora apaixonada. Desde a juventude, Cris é fascinada pelo poder das palavras. Na adolescência, já escrevia contos e poesias, encontrando na escrita uma forma de expressar suas emoções e reflexões sobre a vida. Essa paixão a impulsionou a se dedicar a literatura e escrever a trilogia “Tudo Tem Um Porquê”, mergulhando de cabeça no universo literário.
            <br/><br/>
            Além de sua carreira literária, Cris Zuca é uma presença ativa nas redes sociais e em eventos literários, onde mantém uma forte conexão com seus leitores. Ela valoriza profundamente o feedback e as experiências compartilhadas por seus fãs, utilizando essa interação como combustível para continuar criando histórias que ressoam com o público.
            <br/><br/>
            Como mãe coruja, Cris concilia com maestria a criação dos filhos com suas múltiplas atividades profissionais. A advocacia, exercida em seu próprio escritório, é uma de suas paixões, onde demonstra expertise e dedicação. Já nas palestras que ministra, Cris inspira plateias com suas experiências e as motiva a perseguir seus sonhos.</p>

            <ButtonDefault text="Quero ler agora" />
        </div>
        <div>
            <div id='cris-img-meetcris' className='img img-300'>
                <img src={cris} />
            </div>
        </div>
    </section>
  )
}

export default MeetCris
