import React from 'react'
import warrantyImg from '../Assets/warranty.svg'
import '../Styles/Warranty.css'


function Warranty() {
  return (
    <section id='warranty-section' className='flex-center flex__gap-100 padding-100'>
        <div>
            <div className='img img-100'>
                <img src={warrantyImg} alt='Garantia' />
            </div>
        </div>
        <div id='content-warranty'>
            <h2>Garantia de que...</h2>
            <p>Na Amazon, garantimos a entrega dos seus produtos com a máxima eficiência e segurança. Trabalhamos com uma rede logística robusta e parceiros de confiança para assegurar que suas compras cheguem no prazo estipulado e em perfeitas condições. Caso ocorra qualquer imprevisto, nossa equipe de atendimento ao cliente está disponível 24 horas por dia, 7 dias por semana, para resolver rapidamente qualquer problema e garantir sua satisfação. Com a Amazon, você pode comprar com tranquilidade, sabendo que sua entrega está garantida. Prepare-se para se apaixonar, se emocionar e se surpreender com a Trilogia "Tudo Tem um Porquê"!</p>
        </div>
    </section>
  )
}

export default Warranty
