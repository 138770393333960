import React from 'react'
import FeedbackCard from '../Components/FeedbackCard'
import '../Styles/FeedbackSection.css'
import feedback1 from '../Assets/feedback1.webp'
import feedback2 from '../Assets/feedback2.webp'
import feedback3 from '../Assets/feedback3.webp'
import feedback4 from '../Assets/feedback4.webp'
import feedback5 from '../Assets/feedback5.webp'


function Feedback() {
  return (
    <section id='feedback-section' className='padding-100 flex-center flex-column flex__gap-100'>
        <h1 id='title-feedback-section'>O que estão <span>falando:</span></h1>
        <div className='flex-center flex__gap-50 flex__wrap'>
            <div className='flex-center flex-column flex__gap-20'>
                <FeedbackCard 
                    img={feedback1}
                    name="Alessandro Roberto de Mello"
                    description="Se está à procurando por um romance picante que proporcionará uma leitura prazerosa, esse é o livro, A receita é infalível, amei a narrativa da autora, com certeza quem ler vai ficar empolgadíssimo, com certeza irá apimentar o relacionamento. Parabéns pelo trabalho minha esposa amou. UH LÁ LÁ"
                />
                <FeedbackCard 
                    img={feedback2}
                    name="André Aparecido Melo"
                    description="Fantástico, envolvente e excitante, uma historia com desdobramentos, e muita empolgação. O que nos deixa com vontade de ler o proximo volume logoooo, cadê vc proximo volume.???Rsss."
                />
            </div>
            <div className='flex-center flex-column flex__gap-20'>
                <FeedbackCard 
                    img={feedback3}
                    name="Jessica Camila Oliveira"
                    description="Romance instigante, conta uma história deliciosa e gostosa de ler, acredite vc não vai querer parar. Vale muito a pena a compra, pra dar um gás no relacionamento ou para curtir sozinho(a) mesmo."
                />
                <FeedbackCard 
                    img={feedback4}
                    name="Ana Carla"
                    description="Gente sério estou passada, super envolvente, leitura dinamica e facil, e quem curte um romance hot tem tudo o q se espera, superou 50 tons de cinza 🔥🔥🔥🔥"
                />
                <FeedbackCard 
                    img={feedback5}
                    name="Juliana Betti"
                    description="Simplesmente maravilhoso, um romance de tirar o fôlego, envolvente, sedutor, misterioso, vale a pena cada página. Na expectativa do próximo episódio."
                />
        </div>
        </div>

    </section>
  )
}

export default Feedback
