import './App.css';
import BookEp1 from './Sections/BookEp1';
import BookEp2 from './Sections/BookEp2';
import CrisWorld from './Sections/CrisWorld';
import Episode from './Sections/Episode';
import Feedback from './Sections/Feedback';
import FooterContact from './Sections/FooterContact';
import Home from './Sections/Home';
import MeetCris from './Sections/MeetCris';
import Spoiler from './Sections/Spoiler';
import Warranty from './Sections/Warranty';
import YouFind from './Sections/YouFind';

function App() {
  return (
    <div className="App">
      <Home />
      <CrisWorld />
      <YouFind />
      <Feedback />
      <Episode />
      <Spoiler />
      <BookEp2 />
      <BookEp1 />
      <Warranty />
      <MeetCris />
      <FooterContact />
    </div>
  );
}

export default App;
