import React, { useState, useEffect } from 'react';

function Stopwatch() {
    const initialTime = 3 * 24 * 60 * 60 * 1000 + 3 * 60 * 60 * 1000 + 15 * 60 * 1000;

    const [timeLeft, setTimeLeft] = useState(initialTime);

    useEffect(() => {
        const timer = setInterval(() => {
        setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1000 : 0));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const formatTime = (time) => {
        const days = Math.floor(time / (1000 * 60 * 60 * 24));
        const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);

        return { days, hours, minutes, seconds };
    };

    const { days, hours, minutes, seconds } = formatTime(timeLeft)
  return (
    <div className='stopwatch flex-center flex__gap-20'>
        <div>
            <span>Dias</span>
            <h2>{String(days).padStart(2, '0')}</h2>
        </div>
        <div>
            <span>Horas</span>
            <h2>{String(hours).padStart(2, '0')}</h2>
        </div>
        <div>
            <span>Minutos</span>
            <h2>{String(minutes).padStart(2, '0')}</h2>
        </div>
        <div>
            <span>Segundos</span>
            <h2>{String(seconds).padStart(2, '0')}</h2>
        </div>
    </div>
  )
}

export default Stopwatch
