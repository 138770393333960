import React from 'react'

function ButtonDefault({text, link = 'https://www.amazon.com.br/Tudo-tem-porque%CC%82-Epis%C3%B3dio-encontro-ebook/dp/B09ML7CYNT/ref=sr_1_1?dib=eyJ2IjoiMSJ9.qNCQ9L5cbhma5y0vTtwAuQ.bHLmpQEpTvuWcAKiPN0ngdAlrc9Kv9GKFRyPfQAjanM&dib_tag=se&qid=1733751629&refinements=p_27%3ACris+Zuca&s=digital-text&sr=1-1'}) {
  return (
    <a href={link} target='__blanck' rel='noopener noreferrer'>
      <button className='btn-default'>{text}</button>
    </a>
  )
}

export default ButtonDefault
