import React from 'react'
import '../Styles/Cards.css'

function Cards({img, title, description}) {
  return (
    <div className='cards flex-center flex-column flex__gap-20'>
        <div className='container-images flex-center'>
            <div className='img img-25 flex-center'>
                <img src={img} />
            </div>
        </div>
        <h2 className='title-cards'>{title}</h2>
        <p className='description-cards'>{description}</p>
    </div>
  )
}

export default Cards
