import React from 'react'
import ButtonDefault from '../Components/ButtonDefault';
import InfoText from '../Components/InfoText';

function ContainerOffer({priceOriginal, priceOffer, priceKindle, link}) {
  return (
    <div id='container-offer' className='flex-center flex-column flex__gap-20 padding-50 background-dark-light'>
        <h2>Oferta especial e exclusiva, por tempo limitado!</h2>
        <span>De <span className="striked">{priceOriginal}</span> por apenas:</span>
        <hr/>
        <h1>{priceOffer}</h1>
        <span>ou {priceKindle} versão Kindle.</span>
        <hr/>
        <InfoText />
        <ButtonDefault text="Quero o meu agora" link={link} />
    </div>
  )
}

export default ContainerOffer
