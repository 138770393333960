import React from 'react'
import star from '../Assets/star.svg'
import '../Styles/Feedback.css'

function FeedbackCard({img, alt, name, description}) {
  return (
    <div className='feedback-card flex-center flex-column flex__gap-20'>
        <div className='header-card-feedback flex-center flex__gap-20'>
            <div id='user-feedback-container' className='flex-center flex__gap-10'>
                <div className='img-feedback'>
                    <img src={img} alt={alt} />
                </div>
                <div className='flex-center flex-column container-title-feedback'>
                    <h2 className='name-feedback'>{name}</h2>
                    <h3 className='subtitle-feedback'>Avaliação Amazon</h3>
                </div>
            </div>

            <div id='container-star' className='flex-center'>
                <img src={star} alt='Estrela' />
                <img src={star} alt='Estrela' />
                <img src={star} alt='Estrela' />
                <img src={star} alt='Estrela' />
                <img src={star} alt='Estrela' />
            </div>
        </div>
        <div>
            <p className='description-feedback'>{description}</p>
        </div>
    </div>
  )
}

export default FeedbackCard
