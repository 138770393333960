import React from 'react'
import '../Styles/Home.css'
import logoRedImg from '../Assets/logo-criszuca-white.webp'
import ButtonDefault from '../Components/ButtonDefault'
import InfoText from '../Components/InfoText'

function Home() {
  return (
    <section id='home' className='flex-center background-dark full-window flex__gap-100'>
        <div className='flex-center flex-column flex__gap-50'>
            <div id='logo-criszuca' className='flex-center'>
                <div className='img img-150'>
                    <img src={logoRedImg} alt='Cris Zuca Literatura Hot' />
                </div>
            </div>
            <div id='titles-home' className='flex-center flex-column flex__gap-20'>
                <h2 className='text-start subtitle-main'>Desvende este <br/><span>romance irresistível:</span></h2>
                <h1 className='text-start title-main'>"Tudo tem um porquê"</h1>
            </div>
            <div className='flex-center flex-column flex__gap-20'>
                <InfoText />
                <ButtonDefault text="Ler agora mesmo" />
            </div>
        </div>

        <div id='criszuca-img-home' className='img-background'></div>


    </section>
  )
}

export default Home
